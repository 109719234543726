import {useEffect, useState} from "react"
import {updateToken} from "../../store/api/AuthSlice"
import {useAuth0} from "@auth0/auth0-react"
import {useDispatch} from "../../store/Store"

export const useFetchToken = () => {
    const {getAccessTokenSilently} = useAuth0()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)


    useEffect(() => {
        getAccessTokenSilently({
            detailedResponse: true,
            authorizationParams: {audience: process.env.REACT_APP_AUTH0_AUDIENCE}
        }).then(response => {
            dispatch(updateToken(response.access_token))
            setLoading(false)
        })
    }, [dispatch, getAccessTokenSilently])

    return {isLoading}
}


import {combineReducers, configureStore} from "@reduxjs/toolkit"
import {useDispatch as useStoreDispatch} from "react-redux"
import auth from "./api/AuthSlice";
import {userApi} from "./api/UserApi";
import {traceApi} from "./api/TraceApi";
import {tracerApi} from "./api/TracerApi";
import {spanApi} from "./api/SpanApi";

export const reducer = combineReducers({
    [userApi.reducerPath]: userApi.reducer,
    [tracerApi.reducerPath]: tracerApi.reducer,
    [traceApi.reducerPath]: traceApi.reducer,
    [spanApi.reducerPath]: spanApi.reducer,
    auth
})

export type StoreState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useStoreDispatch<AppDispatch>()


export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({immutableCheck: false})
            .concat(userApi.middleware)
            .concat(tracerApi.middleware)
            .concat(traceApi.middleware)
            .concat(spanApi.middleware)
})


import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {Response} from "../../types/response/Response";
import {apiQuery} from "../utils/Extensions";
import {TracerGetDTO} from "../../types/trace/TracerDTO";


export const tracerApi = createApi({
    reducerPath: "_tracer",
    baseQuery: apiQuery,
    tagTypes: ["tracers"],
    endpoints: builder => ({
        getTracers: builder.query<Response<TracerGetDTO[]>, Partial<void>>({
            query: () => "/tracers",
            providesTags: ["tracers"]
        })
    })
})

export const {
    useGetTracersQuery
} = tracerApi
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {Response} from "../../types/response/Response";
import {apiQuery, getUrl} from "../utils/Extensions";
import {PageDTO, TracePageableDTO} from "../../types/trace/TracePageableDTO";
import {TraceDetailsDTO} from "../../types/trace/TraceDetailsDTO";


export const traceApi = createApi({
    reducerPath: "_trace",
    baseQuery: apiQuery,
    tagTypes: ["traces"],
    endpoints: builder => ({
        getTraces: builder.query<Response<TracePageableDTO>, Partial<PageDTO>>({
            query: (pageDTO) => getUrl("/traces", pageDTO),
            providesTags: ["traces"]
        }),
        getTraceById: builder.query<Response<TraceDetailsDTO>, Partial<string | undefined>>({
            query: (id) => `/traces/${id}`,
            providesTags: ["traces"]
        })
    })
})

export const {
    useGetTracesQuery,
    useGetTraceByIdQuery
} = traceApi
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {Response} from "../../types/response/Response";
import {apiQuery} from "../utils/Extensions";
import {EventGetDTO, SpanGetDTO} from "../../types/trace/SpanGetDTO";


export const spanApi = createApi({
    reducerPath: "_span",
    baseQuery: apiQuery,
    tagTypes: ["spans", "events"],
    endpoints: builder => ({
        getSpans: builder.query<Response<SpanGetDTO[]>, Partial<string | undefined>>({
            query: (trace_id) => `/spans?trace_id=${trace_id}`,
            providesTags: ["spans"]
        }),
        getSpanEvents: builder.query<Response<EventGetDTO[]>, Partial<string>>({
            query: (span_id) => `/events?span_id=${span_id}`,
            providesTags: ["events"]
        })
    })
})

export const {
    useGetSpansQuery,
    useGetSpanEventsQuery
} = spanApi
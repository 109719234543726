import React, {useEffect, useState} from "react"
import styles from "../../assets/css/loader/Loader.module.css"
import loaderLogo from "../../assets/icons/loader/ic_loader.svg"

const Loader: React.FC = () => {
    const [showComponent, setShowComponent] = useState(false)

    useEffect(() => {
        const delay = setTimeout(() => {
            setShowComponent(true)
        }, 50)
        return () => {
            clearTimeout(delay)
        }
    }, [])

    return (
        <div className={showComponent ? styles.loader : styles.loaderstyles + " " + styles.loaderStarting}>
            <div className={styles.loaderImg}>
                <img src={loaderLogo} alt="" width={95} height={95}/>
            </div>
        </div>
    )
}
export default Loader

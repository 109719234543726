import React from 'react';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loader from "./components/loader/Loader";
import AuthorizationProvider from "./features/auth_provider/AuthorizationProvider";
import DataProvider from "./features/data_provider/DataProvider";

const Main = React.lazy(() => import("./features"))

function App() {
    return (
        <AuthorizationProvider>
            <DataProvider>
                <React.Suspense fallback={<Loader/>}>
                    <Main/>
                </React.Suspense>
            </DataProvider>
        </AuthorizationProvider>
    );
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loader/>
});

import React from "react"
import {useFetchToken} from "./useFetchToken";
import Loader from "../../components/loader/Loader";

interface AuthorizationProviderProps {
    children?: React.ReactNode
}

const AuthorizationProvider: React.FC<AuthorizationProviderProps> = ({children}) => {
    const {isLoading} = useFetchToken()

    if (isLoading) return <Loader/>

    return (
        <>
            {children}
        </>
    )
}

export default AuthorizationProvider

import React from "react";
import {useGetSelfUserQuery} from "../../store/api/UserApi";
import Loader from "../../components/loader/Loader";

interface DataProviderProps {
    children?: React.ReactNode
}

const DataProvider: React.FC<DataProviderProps> = ({children}) => {

    const {isLoading} = useGetSelfUserQuery()

    if (isLoading) return <Loader/>

    return (
        <>{children}</>
    )
}

export default DataProvider
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {Response} from "../../types/response/Response";
import {apiQuery} from "../utils/Extensions";
import {UserDTO} from "../../types/user/UserDTO";


export const userApi = createApi({
    reducerPath: "_user",
    baseQuery: apiQuery,
    tagTypes: ["self"],
    endpoints: builder => ({
        getSelfUser: builder.query<Response<UserDTO>, Partial<void>>({
            query: () => `/users/self`,
            providesTags: ["self"]
        })
    })
})

export const {
    useGetSelfUserQuery
} = userApi
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';

import {Auth0Provider} from "@auth0/auth0-react";
import {Provider} from "react-redux";
import {store} from "./store/Store";

import "./assets/css/index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/font-face.css"
import "./assets/styles/globals.css"

const rootElement = document.getElementById("root")

if (!rootElement) throw new Error("Failed to find the root element")
const root = ReactDOM.createRoot(rootElement)

if (!process.env.REACT_APP_AUTH0_DOMAIN) throw new Error("Failed to find the REACT_APP_AUTH0_DOMAIN")
if (!process.env.REACT_APP_AUTH0_CLIENT_ID) throw new Error("Failed to find the REACT_APP_AUTH0_CLIENT_ID")


root.render(
    <Provider store={store}>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}>
            <App/>
        </Auth0Provider>
    </Provider>
);


reportWebVitals();
